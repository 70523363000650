const Spinner = () => {
  return (
    <div className="lds-spinner" style={{ height: "10px", width: "10px" }}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export { Spinner };
